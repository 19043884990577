import React from "react";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { getAccountStatusOptions } from "../../../../selectors/dropdowns";
import { Select } from "../../../helpers";
import { FilterMainComponentProps } from "../Filter";

const AccountStatusSelect = ({
  values,
  onChange,
  placeholder = "All",
  dropdownOptionsFilter,
}: FilterMainComponentProps) => {
  if (
    !["SUPPLIER", "CLIENT", "DISTRIBUTOR"].includes(
      dropdownOptionsFilter.company_type,
    )
  ) {
    throw new Error("Invalid company type");
  }

  const accountStatusOptions = createOptions(
    useSelector((state) =>
      getAccountStatusOptions(state, dropdownOptionsFilter),
    ),
    "key",
    "value",
    { addAll: true },
  );

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      // @ts-ignore
      options={accountStatusOptions}
      placeholder={placeholder}
    />
  );
};

export default AccountStatusSelect;
